<template>
  <b-sidebar id="add-new-subject-sidebar" :visible="isAddNewSubjectSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-subject-sidebar-active', val)">
    <template #default="{ hide }">
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">Add Subject</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <zape-gallery-model :props_hideShowModel="showImageModel" @modelClose="modelClose"
        @saveCategoryImage="saveImage" />
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <validation-provider #default="validationContext" name="Subject Name" rules="required">
            <b-form-group label="Subject Name" label-for="Subject Name">
              <b-form-input id="Subject-Name" v-model="subject_data.subject_name" autofocus
                :state="getValidationState(validationContext)" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <br />
          <img style="width: 200px; height: 150px" :src="subject_data.subject_img" alt="subject" />
          <br />
          <br />
          <div class="d-flex gap-2">
            <b-button variant="primary" @click="showgalleryimage">
              <span class="d-none d-sm-inline">Image gallery</span>
            </b-button>
            <upload-image @saveUploadImage="saveUploadImage" />
          </div>
          <div class="d-flex bg-light text-light align-items px-3 py-2"
            style="position: absolute; bottom: 0%; left: 0%; width: 100%">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="submit" @click="hide"
              :disabled="submitbutton">
              Add
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" style="margin-left: 10px"
              variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BFormFile,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    ZapeGalleryModel: () => import("../../../components/Model/ZapeGalleryModel.vue"),
    UploadImage: () => import("../../../components/Model/UploadImage.vue"),
    BSidebar,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewSubjectSidebarActive",
    event: "update:is-add-new-subject-sidebar-active",
  },
  props: {
    isAddNewSubjectSidebarActive: {
      type: Boolean,
      required: true,
    },
    classId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      subject_data: {
        subject_name: "",
        subject_img:
          "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg",
      },
      post_subject_data: "",
      showImageModel: false,
    };
  },
  computed: {
    submitbutton() {
      return !(
        this.subject_data.subject_name.length > 0 &&
        this.subject_data.subject_img.length > 0 &&
        this.subject_data.subject_img !== "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg"
      );
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.subject_data = {
        subject_name: "",
        subject_img:
          "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg",
      };
      this.validated = false;
    },
    onSubmit() {
      this.subject_data.class_id = this.classId;
      this.post_subject_data = this.subject_data;
      this.$emit("sendsubjectdata", this.post_subject_data);
    },
    saveUploadImage(url) {
      this.subject_data.subject_img = url;
    },
    modelClose(modelState) {
      this.showImageModel = modelState;
    },
    saveImage(url) {
      this.subject_data.subject_img = url;
    },
    showgalleryimage() {
      this.showImageModel = true;
    },
  },
};
</script>
